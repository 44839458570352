.info-blackout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

    opacity: 0;
    transition: opacity 0.3s;

    z-index: 200;
}

.info-blackout.active {
    opacity: 1;
}

.info-popout {
    position: fixed;
    top: 0;
    left: -100vw;
    width: 38rem;
    max-width: 100vw;
    height: 100%;
    background-color: var(--uber-white);
    color: var(--uber-black);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0;

    transition: left 0.3s;

    z-index: 200;
}

.info-popout.active {
    left: 0;
}

.pop-content {
    position: relative;
    width: 100%;
    height: 100%;
}

.close-pop-button {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    width: 2.1rem;
    height: 2.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.close-pop-button svg {
    stroke: rgba(255, 255, 255, 0.5);
    stroke-width: 1;
}
