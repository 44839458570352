.trailblazer-card {
    position: relative;

    display: flex;
    flex-direction: row;
    gap: 0;

    width: 100%;
    min-width: 60vw;
    max-width: calc(100% - 4rem);
    height: 13.5rem;
    margin: 0;
    padding: 0;
    border-radius: 1.5rem;
    background-color: var(--uber-white);
    color: var(--uber-black);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
}

@media screen and (min-width: 500px){
    .trailblazer-card {
        max-width: 70vw;
    } 
}

@media screen and (min-width: 627px){
    .trailblazer-card {
        width: 28.5rem;
        min-width: 28.5rem;
    } 
}

.trailblazer-card__image {
    width: 8.5rem;
    height: 11.5rem;

    border-radius: 1.5rem;
    margin: 1rem 0 1rem 1rem;
    
    background-size: cover;
    background-position: center;

    overflow: hidden;
}

.trailblazer-card__image.carousel-inner {
    margin: 1rem 0;
}

.trailblazer-card__info {
    display: flex;
    flex-direction: column;
    gap: 0;
    flex-grow: 1;

    text-align: left;

    padding: 1rem;
}

@media screen and (min-width: 768px){
    .trailblazer-card {
        width: 49rem;
        max-width: 80vw;
        height: 16rem;
    }

    .trailblazer-card__image {
        width: 20rem;
        height: 16rem;
        border-radius: 1.5rem 0 0 1.5rem;
        margin: 0;
    }

    .trailblazer-card__info { 
        padding: 2rem 1.5rem 1.5rem;
    }
}

.trailblazer-card__info p {
    margin: 0 0 0.4rem;
}

.trailblazer-card__info h3 {
    margin: 0 0 0.4rem;
}

.trailblazer-card__info__name {
    margin: 0 0 0.5rem;
    color: var(--uber-black);
    text-align: left;
}

.trailblazer-card__info__location {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;;
    margin: 0 0 1rem;
    color: var(--uber-black);
    text-align: left;
}

.trailblazer-card__info__location p {
    margin: 0;
}

.trailblazer-card__info__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

.trailblazer-card__info__buttons svg {
    height: 1.3rem;
}

.trailblazer-card__info__buttons button:hover {
    color: var(--uber-white);
}

.trailblazer-card__info__buttons button:hover svg path {
    fill: var(--uber-white);
}

.trailblazer-card__info__buttons__info {
    display: flex;
    padding: 0rem 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.66667rem;
    background: var(--bg-light-button);
    color: var(--uber-black);
}

.trailblazer-card__info__buttons__instagram {
    display: none;
}
@media screen and (min-width: 768px){
    .trailblazer-card__info__buttons__instagram {
        display: flex;
        padding: 0rem 1rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.66667rem;
        background: var(--bg-light-button);
        color: var(--uber-black);
    }
}

.trailblazer-card__info__buttons__order {
    display: flex;
    flex-grow: 1;
    height: 4rem;
    padding: 0rem 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.66667rem;
    background: var(--uber-black);
    color: var(--uber-white);
}
