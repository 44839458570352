.prev-winner-card {
    display: flex;
    flex-direction: column;
    gap: 0;

    width: 100%;
    height: 34rem;
    margin: 0 0 2rem;
    padding: 0;
    border-radius: 1.5rem;
    background-color: var(--uber-white);
    color: var(--uber-black);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

.prev-winner-card__top-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;

    width: 100%;
    height: 3rem;
    background-color: var(--uber-dark-green);
    color: var(--uber-white);
}

.prev-winner-card__top-banner svg {
    height: 1.3rem;
    fill: var(--uber-white);
}

.prev-winner-card__top-banner path {
    fill: var(--uber-white);
}

.prev-winner-card__image {
    width: 100%;
    height: 16.5rem;
    background-size: cover;
    background-position: center;
}

.prev-winner-card__info {
    display: flex;
    flex-direction: column;
    gap: 0;
    flex-grow: 1;

    padding: 1.5rem 1.5rem;
}

.prev-winner-card__info__name {
    margin: 0 0 0.5rem;
    color: var(--uber-black);
    text-align: left;
}

.prev-winner-card__info__location {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    margin: 0;
    color: var(--uber-black);
    text-align: left;
}

.prev-winner-card__info__location p {
    margin: 0;
}

.prev-winner-card__info__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

.prev-winner-card__info__buttons svg {
    height: 1.3rem;
}

.prev-winner-card__info__buttons button:hover {
    color: var(--uber-white);
}

.prev-winner-card__info__buttons button:hover svg path {
    fill: var(--uber-white);
}

.prev-winner-card__info__buttons__info {
    display: flex;
    padding: 0rem 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.66667rem;
    background: var(--bg-light-button);
    color: var(--uber-black);
}

.prev-winner-card__info__buttons__instagram {
    display: flex;
    padding: 0rem 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.66667rem;
    background: var(--bg-light-button);
    color: var(--uber-black);
}

.prev-winner-card__info__buttons__order {
    display: flex;
    flex-grow: 1;
    height: 4rem;
    padding: 0rem 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.66667rem;
    background: var(--uber-black);
    color: var(--uber-white);
}

.prev-winner-card__info__buttons__vote {
    display: flex;
    width: 100%;
    height: 4rem;
    min-width: 100%;
    min-height: 4rem;
    max-width: 100%;
    max-height: 4rem;
    padding: 0rem 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.66667rem;
    background: var(--uber-black);
    color: var(--uber-white);
}
