.accordian-container {
    display: flex;
    flex-direction: column;

    width: 100%;
    background-color: #000;
}

.accordian-section {
    display: flex;
    flex-direction: column;

    width: 100%;

    border-bottom: 1px solid #fff;
}

.accordian-content {
    overflow: hidden;

    transition: all 0.3s ease-in-out;
}

.accordian-section p {
    color: #bdbdbd;
    font-weight: 400;

    margin: 0;
    padding: 0 2rem 2.5rem 2rem;

    transition: all 0.3s ease-in-out;
}

.accordian-button {
    display: flex;
    flex-direction: row;
    height: fit-content;

    padding: 2rem;

    text-align: left;
    font-size: 1.3rem;
    font-weight: 500;
    color: #fff;
}

.accordian-button .content {
    display: block;
    flex-grow: 1;
    padding: 0;
    margin: 0;
}

.accordian-button .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 1.3rem;

    transition: all 0.3s ease-in-out;
}

.accordian-section button:hover {
    background-color: #000;
}

.accordian-section-closed p {
    color: #000;
}

