/* Setup */

:root {
    --uber-black: #000000;
    --uber-white: #ffffff;
    --uber-green: #06c167;
    --uber-dark-green: #0e8345;
    --bg-beige: #e9e6d0;
    --bg-yellow: #f6cd01;
    --bg-light-button: #f3f3f3;

    --desktop-constraint: 1272px;

    font-size: 10px;
}

* {
    box-sizing: border-box;
}

@font-face {
    font-family: UberMove;
    src: url(../uber-assets/UberMoveFont/Uber_Move-Light.woff);
    font-weight: lighter;
}

@font-face {
    font-family: UberMove;
    src: url(../uber-assets/UberMoveFont/Uber_Move-Light.woff);
    font-weight: 200;
}

@font-face {
    font-family: UberMove;
    src: url(../uber-assets/UberMoveFont/Uber_Move-Regular.woff);
    font-weight: normal;
}

@font-face {
    font-family: UberMove;
    src: url(../uber-assets/UberMoveFont/Uber_Move-Regular.woff);
    font-weight: 400;
}

@font-face {
    font-family: UberMove;
    src: url(../uber-assets/UberMoveFont/Uber_Move-Medium.woff);
    font-weight: 500;
}

@font-face {
    font-family: UberMove;
    src: url(../uber-assets/UberMoveFont/Uber_Move-Bold.woff);
    font-weight: bold;
}

@font-face {
    font-family: UberMove;
    src: url(../uber-assets/UberMoveFont/Uber_Move-Bold.woff);
    font-weight: 600;
}

/* @font-face {
    font-family: Questrial;
    src: url(../uber-assets/Questrial-Regular.ttf);
    font-weight: normal;
} */

/* Elements */

body {
    margin: 6rem 0 0 0;
    padding: 0;
    font-family: 'UberMove', sans-serif;
    font-size: 1.2rem;
    color: var(--uber-black);
    background-color: var(--uber-white);

    overflow-x: hidden;
}

@media screen and (min-width: 1160px) {
    body {
        margin: 13rem 0 0 0;
        font-size: 1.6rem;
    }
}

p {
    font-family: 'UberMove', sans-serif;
}

h1 {
    margin: 0 0 1.5rem;
    font-family: 'UberMove', sans-serif;
    font-size: 3rem;
    font-weight: 700;
    color: var(--uber-black);
}

h2 {
    margin: 0 0 0.2rem;
    font-family: 'UberMove', sans-serif;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--uber-black);
}

h3 {
    margin: 0 0 0.2rem;
    font-family: 'UberMove', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--uber-black);

}

ul {
    padding-inline-start: 20px;
}

@media screen and (min-width: 768px) {
    body {
        font-size: 1.3rem;
    }
    
    h1 {
        font-size: 4rem;
    }

    h2 {
        font-size: 1.6rem;
    }

    h3 {
        font-size: 1.4rem;
    }
}

@media screen and (min-width: 1370px) {
    :root {
        font-size: 12px;
    }

    body {
        font-size: 1.3rem;
    }
    
    h1 {
        font-size: 4.3rem;
    }

    h2 {
        font-size: 1.8rem;
    }

    h3 {
        font-size: 1.5rem;
    }
}

button {
    font-family: 'UberMove', sans-serif;
    background-color: var(--uber-black);
    color: var(--uber-white);
    border: 0px solid var(--uber-white);
    border-radius: 8px;
    padding: 0.5rem 1rem;
    /* min-width: 200px;
    min-height: 50px; */
    height: 4rem;
    text-align: center;
    cursor: pointer;
    font-weight: 500;

    transition: background-color 0.3s, color 0.3s;
}

button.square {
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    min-height: 4rem;
    max-width: 4rem;
    max-height: 4rem;
}

button.nav:not([disabled]):hover {
    background-color: var(--uber-green);
    color: var(--uber-white);
}

button.black {
    background-color: var(--uber-black);
    color: var(--uber-white);
}

button.black:not([disabled]):hover {
    background-color: #242424;
}

button.light:not([disabled]):hover {
    background-color: #d8d8d8;
    color: var(--uber-black);
}

button.light:not([disabled]):hover svg {
    fill: var(--uber-black)
}

button.light:not([disabled]):hover path {
    fill: var(--uber-black)
}

button:disabled {
    cursor: default;
}

.small-info-icon {
    height: 1.3rem;
}

.voted-holder {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    font-family: 'UberMove', sans-serif;
    color: var(--uber-green);
    padding: 0.5rem 1rem;
    height: 4rem;
    text-align: center;
    font-weight: 500;
}

.voted-holder.inline {
    display: flex;
}

.voted-holder.under {
    display: none;
}

.voted-holder.side-panel {
    font-size: 1.5rem;
    color: var(--uber-white);
}

@media screen and (min-width: 768px) {
    .voted-holder.inline {
        display: none;
    }
    
    .voted-holder.under {
        display: flex;
    }
}

/* Utilities */

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.grow {
    flex-grow: 1;
}

.grow-100 {
    flex-grow: 100;
}

.shrink {
    flex-shrink: 1;
}

.extra-bottom-pad {
    padding-bottom: 3rem;
}

.em {
    font-style: italic;
}

/* Components */

/* Nav */
nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    position: fixed;
    left: 0;
    z-index: 100;
    width: 100%;
    padding: 2rem 2rem;
    margin: 0;
    background-color: var(--uber-black);
    color: var(--uber-white);
    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.30));
    font-weight: 500;

    top: -8rem;
    opacity: 0;

    animation: nav-slide-in 0.5s forwards;
    animation-timing-function: ease-out;
}

@keyframes nav-slide-in {
    0% {
        top: -8rem;
        opacity: 0;
    }
    100% {
        top: 0;
        opacity: 1;
    }
}

.burger-menu {
    display: block;
    color: var(--uber-white);
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    max-width: 2rem;
    max-height: 2rem;
    background-image: url(../uber-assets/burger-menu-icon.svg);
    background-position: center;
    background-size: contain;

    cursor: pointer;
}

.mob-blackout {
    position: fixed;
    top: 6rem;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

    opacity: 0;
    transition: opacity 0.3s;
}

.mob-blackout.active {
    opacity: 1;
}

.mob-menu {
    position: fixed;
    top: 0;
    left: -100vw;
    min-width: 50vw;
    max-width: 100vw;
    height: calc(100vh - 6rem);
    background-color: var(--uber-white);
    color: var(--uber-black);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 2rem 4rem;
    margin-top: 6rem;

    transition: left 0.3s;

    overflow-y: auto;
}

.mob-menu.active {
    left: 0;
}

.mob-menu button {
    width: 22rem;
    max-width: 100%;
}

.mob-menu-bottom {
    flex-shrink: 1;
    margin-bottom: 1rem;
}

.nav-logo {
    display: block;
    height: 2rem;
    max-width: calc(100vw - 10rem);
}

.nav-links {
    display: none;
}

@media screen and (min-width: 840px) {
    nav {
        margin: 2rem auto;
        padding: 1rem 3rem;
        border: 0px solid var(--uber-white);
        border-radius: 100em;
        width: calc(100vw - 4rem);
        max-width: var(--desktop-constraint);
        left: 50%;
        transform: translateX(-50%);
    }
    
    .burger-menu {
        display: none;
    }

    .mob-blackout {
        display: none;
    }

    .mob-menu {
        display: none;
    }

    .nav-links {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }

    .nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        background-color: var(--uber-black);
        color: var(--uber-white);
        border: 0px solid var(--uber-white);
        border-radius: 100em;
        padding: 0.5rem 2rem;
        cursor: pointer;
        font-size: 1.2rem;

        transition: background-color 0.3s, color 0.3s;
    }

    .nav-link:hover {
        background-color: var(--uber-green);
    }
}

@media screen and (min-width: 960px) {
    .nav-link {
        font-size: 1.3rem;
    }
}

/* Containers */

.section-container {
    width: 100%;
    padding: 2rem 1rem;
    margin: 0;
}

@media screen and (min-width: 768px) {
    .section-container {
        margin: auto;
        padding: 2rem 3rem;
        max-width: var(--desktop-constraint);
    }
}

@media screen and (min-width: 940px) {
    .section-container {
        width: calc(100vw - 8rem);
    } 
}

.content {
    margin: 0 2rem;
}

.coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: 1rem;
    text-align: center;
    width: 100vw;
    height: 100vh;
}

.coming-soon-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: 1rem;
    text-align: center;
}

.coming-soon img {
    width: 90vw;
    max-width: 398px;
}

.coming-soon h1 {
    font-size: 1.2rem;
}

@media screen and (min-width: 250px) {
    .coming-soon h1 {
        font-size: 2rem;
    }
}

@media screen and (min-width: 366px) {
    .coming-soon h1 {
        font-size: 2.6rem;
    }
}

.header-container {
    display: grid;
    grid-template-columns: 1fr;
}

.header-container button {
    margin: 2rem 0;
    width: 100%;
    min-width: 100%;
    max-width: 400px;
}

@media screen and (min-width: 768px) {
    .header-container button {
        width: initial;
        min-width: 200px;
    }
}

.header-split {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
    z-index: 90;
}

.header-img-area {
    position: relative;
    height: 60vw;
    overflow: visible;
}

.header-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 100%;
    z-index: 1;
}

@media screen and (min-width: 768px) {
    .header-container {
        grid-template-columns: 55%;
        min-height: 450px;
    }

    .header-split {
        margin-top: 0rem;
    }

    .header-img-area {
        top: 18rem;
        left: 90%;
        width: 100%;
        height: 0;
    }

    .header-img {
        position: relative;
        top: 200px;
        left: 145%;
        height: 60rem;
    }
}

@media screen and (min-width: 1160px) {
    .header-img-area {
        top: 17rem;
        
    }
}

@media screen and (min-width: 1370px) {
    .header-img-area {
        top: 12rem;
        
    }
}

section {
    padding: 2rem 0;
}

.section-image-heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    flex-shrink: 1;
}

@media screen and (min-width: 768px) {
    .section-image-heading-container {
        flex-direction: row;
        justify-content: center;
    }
    
}

.section-heading-image {
    width: 128px;
    height: 128px;
}

@media screen and (min-width: 768px) {
    .section-heading-image {
        width: 60px;
        height: 60px;
    }
}

@media screen and (min-width: 1160px) {
    .section-heading-image {
        width: 70px;
        height: 70px;
    }
}

.roty-section {
    background-color: var(--bg-beige);
    background-image: url(../uber-assets/beige-bg-overlay.png);
    background-position: center top;
    background-size: 100%;
    background-repeat: repeat-y;

    min-height: 400px;

    text-align: center;
}

.roty-section-heading {
    margin: auto;
}

.roty-section-heading h1 {
    margin: 0;
}

@media screen and (min-width: 768px) {
    .roty-section-heading {
        width: calc(100% - 12rem);
    }
}

@media screen and (min-width: 1160px) {
    .roty-section-heading {
        width: 950px;
    }
}

.cat-buttons-container {
    display: none;
}

.cat-drop-down {
    width: 100%;
    height: 4rem;
    margin-bottom: 2rem;
    outline: none;
    border: 0px solid var(--uber-black);
    border-radius: 0.66667rem;
    padding: 0 1rem;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../uber-assets/drop-down-arrow.svg) no-repeat calc(100% - 1.5rem) var(--uber-white);
}

.cat-drop-down.trailblazers {
    border: 1px solid var(--uber-black);
}

.cat-button div {
    margin: 0 2px 0 0;
}

.cat-button img {
    margin: 0 0 0 2px;
}

@media screen and (min-width: 768px) {
    .cat-buttons-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        margin-top: 1rem;
        margin-bottom: 5rem;
    }

    .cat-drop-down {
        display: none;
    }

    .cat-button {
        display: flex;
        height: 4rem;
        padding: 0rem 1rem;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        flex-shrink: 0;
    
        border-radius: 8.33333rem;
        color: var(--uber-black, #000);
        font-weight: 500;
        font-size: 1.1rem;
    
        transition: background-color 0.3s, color 0.3s;
    }
    .cat-button img {
        width: 18px;
    }

    @media screen and (min-width: 1370px) {
        .cat-button {
            font-size: 1.1rem;
        }
    }
    
    .cat-button.region {
        width: 10rem;
        min-width: 10rem;
        
        border: 0px solid var(--uber-white);
        background: var(--uber-white, #FFF);
        color: var(--uber-black, #000);
    }
    
    .cat-button.tbcat {
        width: 18rem;
        min-width: 18rem;
        
        border: 0px solid var(--bg-light-button);
        background: var(--bg-light-button, #FFF);
    }

    .cat-button:not([disabled]):hover {
        background-color: var(--uber-green);
    }

    .cat-button.active {
        border: 2px solid var(--uber-green);
    }

    .cat-button.active div {
        margin: 0;
    }

    .cat-button.active img {
        margin: 0;
    }
}

.cards-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
}

@media screen and (min-width: 627px) {
    .cards-container {
        flex-direction: row;
        flex-wrap: wrap;
    } 
}

.trailblazers-section {
    background-color: var(--uber-white);
    background-image: url(../uber-assets/circles-bg-overlay.png);
    background-position: right -50px;
    background-size: 50%;
    background-repeat: no-repeat;

    min-height: 400px;

    text-align: center;
}


.previous-winners-section {
    background-color: var(--bg-yellow);

    text-align: center;
}

.previous-winners-content {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 1rem;

    text-align: left;
}

.previous-winners-mob-image-container {
    width: 100%;
    height: 90vw;
    background-color: var(--bg-yellow);
    background-image: url(../uber-assets/prev-winners-crowd_800.png);
    background-position: center top;
    background-size: 110%;
    background-repeat: no-repeat;
    margin-top: -4rem;
}

@media screen and (min-width: 768px) {
    .previous-winners-section {
        min-height: 350px;
        background-image: url(../uber-assets/prev-winners-crowd_800.png);
        background-position: 120% center;
        background-size: 50rem;
        background-repeat: no-repeat;
    }

    .previous-winners-content {
        width: 45%;
    }

    .previous-winners-mob-image-container {
        display: none;
    }
}

@media screen and (min-width: 840px) {
    .previous-winners-section {
        background-position: 110% center;
    }
}

@media screen and (min-width: 916px) {
    .previous-winners-section {
        background-position: 95% center;
    }
}

@media screen and (min-width: 1060px) {
    .previous-winners-section {
        background-position: 90% center;
    }
}

@media screen and (min-width: 1400px) {
    .previous-winners-section {
        background-position: 80% center;
    }
}

@media screen and (min-width: 1600px) {
    .previous-winners-section {
        background-position: 74% center;
    }
}

.previous-winners-content h2 {
    font-size: 3rem;
    text-align: left;
    margin-bottom: 0;
}

.previous-winners-content p {
    text-align: left;
    margin-top: 0;
    margin-bottom: 1rem;
}

.previous-winners-content button {
    background-color: var(--bg-light-button);
    color: var(--uber-black);
    width: 20rem;
}

.faq-section {
    background-color: var(--uber-black);
    color: var(--uber-white);
    padding: 0;
}

.faq-section h1 {
    text-align: center;
    color: var(--uber-white);
}

.faq-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 3rem 0;
}

@media screen and (min-width: 768px) {
    .faq-section {
        padding: 5rem 0;
        min-height: 400px;
    }
}

footer {
    background-color: var(--uber-white);
    color: var(--uber-black);
    padding: 2rem 0 1rem;
}

.footer-container {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: top;
}

.footer-image-container {
    display: flex;
    flex-shrink: 1;
}

.footer-logo {
    height: 2.5rem;
    max-width: calc(100vw - 8rem);
    margin-bottom: 2rem;
}

.footer-logo.in-menu {
    height: auto;
    width: 14rem;
    max-width: 100%;
}

.footer-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;
}

.footer-content.in-menu {
    width: 22rem;
    max-width: 100%;
    
}

.footer-content p {
    margin-top: 0;
}

.footer-content.in-menu p {
    font-size: 0.9rem;
}

@media screen and (min-width: 768px) {
    footer {
        padding: 4rem 0 2rem;
    }

    .footer-container {
        flex-direction: row;
        gap: 2rem;
    }

    .footer-content {
        text-align: right;
    }
}

.info-pop-content-holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.info-pop-image {
    width: 100%;
    height: 19.5rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.info-pop-content {
    display: flex;
    flex-direction: column;
    gap: 0;
    flex-grow: 1;
    max-height: calc(100vh - 27.5rem);
    padding: 2rem 4rem 2rem;
}

.prev-winners-pop-content {
    display: flex;
    flex-direction: column;
    gap: 0;
    flex-grow: 1;
    max-height: 100%;
    padding: 0;
}

.info-pop-scroll-area {
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    padding-right: 0.5rem;
}

.prev-winners-pop-scroll-area {
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    padding: 0 2rem;
}

.info-pop-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-shrink: 1;
    background-color: var(--uber-green);
    padding: 2rem 4rem;
}

h1.prev-winners {
    margin-top: 2rem;
}

h2.prev-winners {
    margin: 5rem 0 3rem;
    padding-bottom: 0rem;
    font-size: 2.8rem;
    color: var(--uber-black);
}

p.prev-winners {
    padding-bottom: 2rem;
}

.carousel-dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--uber-white);
    border: 1px solid rgba(0, 0, 0, 0.30);
    margin: 0.5rem;
    opacity: 0.5;
}

.carousel-dot.active {
    opacity: 1;
}

.side-card__info__buttons__instagram {
    display: flex;
    padding: 0rem 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.66667rem;
    background: var(--bg-light-button);
    color: var(--uber-black);
}

.side-card__info__buttons__order {
    display: flex;
    flex-grow: 1;
    height: 4rem;
    padding: 0rem 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.66667rem;
    background: var(--bg-light-button);
    color: var(--uber-black);
}

.side-card__info__buttons__vote {
    display: flex;
    width: 100%;
    height: 4rem;
    min-width: 100%;
    min-height: 4rem;
    max-width: 100%;
    max-height: 4rem;
    padding: 0rem 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.66667rem;
    background: var(--uber-black);
    color: var(--uber-white);
}

.card-ribbon {
    position: absolute;
    z-index: 1;

    width: 60px;
    height: 60px;
    top: -6px;
    left: -6px;
}

@media screen and (min-width: 768px) {
    .card-ribbon {
        width: 90px;
        height: 90px;
        top: -9px;
        left: -9px;
    }
}

@media screen and (min-width: 1160px) {
    .card-ribbon {
        width: 122px;
        height: 122px;
        top: -13px;
        left: -13px;
    }
}

.recaptcha-holder {
    background-color: rgba(0, 0, 0, 0.75);
    width: 0;
    height: 0;
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    z-index: 1000;
}

.recaptcha-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    background-color: var(--uber-white);
    border-radius: 0.66667rem;
    padding: 4rem;
    margin: auto;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
}

.recaptcha-on {
    width: 100vw;
    height: 100vh;
}

/* Animating sections in */
.header-container {
    opacity: 0;

    animation: header-anim-in 1s forwards;
    animation-timing-function: ease-out;
    animation-delay: 0.4s;
}

@keyframes header-anim-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

section {
    padding-top: 7rem;
    opacity: 0;
}

.section-anim-in {
    animation: section-anim-in 0.6s forwards;
    animation-timing-function: ease-out;
}

@keyframes section-anim-in {
    0% {
        padding-top: 7rem;
        opacity: 0;
    }
    100% {
        padding-top: 2rem;
        opacity: 1;
    }
}

.card-anim-start {
    opacity: 0;
    transform: translateY(30px);
}

.card-anim-in {
    animation: card-anim-in 0.3s forwards;
    animation-timing-function: ease-out;
}

@keyframes card-anim-in {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.card-anim-out {
    animation: card-anim-out 0.3s forwards;
    animation-timing-function: ease-out;
}

@keyframes card-anim-out {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-30px);
    }
}

a {
    text-decoration: none;
    color: var(--uber-dark-green);
}

a:hover {
    text-decoration: underline;
}

a:active {
    color: var(--uber-dark-green);
}

a:visited {
    color: var(--uber-dark-green);
}

.left-align {
    text-align: left;
}

.react-multiple-carousel__arrow {
    background: rgba(0, 0, 0, 0.3) !important;
    min-width: 28px !important;
    min-height: 28px !important;
    max-width: 28px !important;
    max-height: 28px !important;
}

.react-multiple-carousel__arrow--right::before {
    font-size: 12px !important;
    margin-right: -2px;
}

.react-multiple-carousel__arrow--left::before {
    font-size: 12px !important;
    margin-left: -2px;
}

.trailblazer-nominate-button {
    display: flex;
    width: 20rem;
    height: 4rem;
    min-width: 20rem;
    min-height: 4rem;
    max-width: 20rem;
    max-height: 4rem;
    padding: 0rem 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.66667rem;
    background: var(--uber-black);
    color: var(--uber-white);
}
